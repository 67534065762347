// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { OrbitApiMeta, Property } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const usePropertiesStore = defineStore('properties', () => {
  // fetch repositories
  const { $properties } = useNuxtApp()

  // state
  const properties = ref<Property[]>([])

  // actions
  async function index (config?: NitroFetchOptions<string>) {
    const { properties: _properties } =
      await $properties
        .index<{ properties: Property[], meta: OrbitApiMeta }>(config)
    properties.value = _properties as Property[] || []
    return properties
  }

  return {
    properties,
    index
  }
})
